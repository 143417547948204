<button [disabled]="submitting" class="btn btn-sm btn-info" *ngIf="list && order.status !== 'PAYMENT_FAILED'" routerLink="/orders/{{order.id}}">View</button>
<button class="btn btn-sm btn-info" *ngIf="!list && order.type!=='TestKit' && order.status !== 'REJECTED_BY_PHARMACY'" (click)="printPrescription()">Print Prescription</button>

<div class="preload" style="display: none">
  <img src="/assets/img/watermark.png" />
  <img src="{{order.doctorSignatureUrl}}" />
</div>

<button [disabled]="submitting" class="btn btn-sm btn-primary" *ngIf="!isVerification && isLoggedIn && order.status === 'WAITING_FOR_PHARMACY_APPROVAL'" (click)="approveModal.show()">Approve</button>
<button [disabled]="(!isVerification && isLoggedIn && order.status !== 'AWAITING_PHARMACY_DISPENSE') || submitting" class="btn btn-sm btn-warning" *ngIf="!isVerification && isLoggedIn && order.status !== 'WAITING_FOR_PHARMACY_APPROVAL' && order.status !== 'AWAITING_PHARMACY_DISPATCH' && order.status !== 'DELIVERED' && order.status !== 'AWAITING_PHARMACY_DELIVER' && order.status !== 'REJECTED_BY_PHARMACY'" (click)="approveModal.show()">Dispense</button>
<button [disabled]="submitting" class="btn btn-sm btn-success" *ngIf="!isVerification && isLoggedIn && order.status === 'AWAITING_PHARMACY_DISPATCH' && order.status !== 'DELIVERED'" (click)="openDispatchModal()">Dispatch</button>
<button [disabled]="submitting" class="btn btn-sm btn-success" *ngIf="!isVerification && isLoggedIn && order.status === 'AWAITING_PHARMACY_DELIVER' && order.status !== 'DELIVERED'" (click)="completeModal.show()">Deliver</button>
<button [disabled]="(!isVerification && isLoggedIn && order.status !== 'WAITING_FOR_PHARMACY_APPROVAL' && order.status !== 'AWAITING_PHARMACY_DISPENSE') || submitting" class="btn btn-sm btn-danger" *ngIf="order.status !== 'REJECTED_BY_PHARMACY' && order.status !== 'AWAITING_ADMIN_ACTION_REJECTED_BY_PHARMACY' && order.status !== 'DELIVERED' && order.status !== 'PAYMENT_FAILED' && order.status !== 'APPROVED_BY_PHARMACY' && order.status !== 'CANCELED_BY_PHARMACY' && order.status !== 'AWAITING_PHARMACY_DISPATCH' && order.status !== 'AWAITING_PHARMACY_DELIVER'"
        (click)="rejectModal.show()">Reject</button>
<button *ngIf="!isLoggedIn" class="btn btn-sm btn-danger" (click)="goBack()">Close</button>

<div bsModal #rejectModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <form #rejectForm="ngForm" (ngSubmit)="rejectModal.hide(); reject()" autocomplete="off" novalidate>
          <div class="form-group">
            <p><label>Please enter a reason for rejection</label></p>
            <label class="radio-inline">
              <input name="rejectReason" type="radio" value="COMPLETELY_OUT_OF_STOCK" required [(ngModel)]="rejectReason"> Product completely out of stock
            </label>
            <label class="radio-inline">
              <input name="rejectReason" type="radio" value="OTHER" required [(ngModel)]="rejectReason"> Other
            </label>
          </div>
          <div class="form-group" *ngIf="rejectReason == 'OTHER'">
            <label>Please enter a details for rejection</label>
            <textarea name="rejectReasonDetails" rows="3" class="form-control" placeholder="Reason details" required [(ngModel)]="rejectReasonDetails"></textarea>
          </div>
          <div class="form-group">
            <label>PIN Number</label>
            <app-masked-input [(value)]="pin"></app-masked-input>
          </div>
          <div class="form-group">
            <label>Name of Pharmacist</label>
            <input class="form-control" type="text" name="name" required [(ngModel)]="name" />
          </div>
          <button type="reset" class="btn btn-sm btn-default" (click)="rejectModal.hide()">Cancel</button>
          <button [disabled]="rejectForm.form.invalid || pin === ''" type="submit" class="btn btn-sm btn-danger">Reject</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div bsModal #proposeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <form #proposeForm="ngForm" (ngSubmit)="proposeModal.hide(); propose()" autocomplete="off" novalidate>
          <div class="form-group">
            <label>Enter price of {{order.treatment}}</label>
            <div class="input-group">
              <div class="input-group-addon">£</div>
              <input type="number" class="form-control" placeholder="Amount" [(ngModel)]="price" name="price" required>
            </div>
          </div>
          <div class="form-group">
            <label>PIN Number</label>
            <app-masked-input [(value)]="pin"></app-masked-input>
          </div>
          <div class="form-group">
            <label>Name of Pharmacist</label>
            <input class="form-control" type="text" name="name" required [(ngModel)]="name" />
          </div>
          <button type="reset" class="btn btn-sm btn-default" (click)="proposeModal.hide()">Cancel</button>
          &nbsp;
          <button [disabled]="proposeForm.form.invalid || pin === ''" type="submit" class="btn btn-sm btn-primary">Propose</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div bsModal #approveModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-body">
        <form #approveForm="ngForm" autocomplete="off" novalidate>
          
          <br/>
          <div *ngIf="!deliveryOrder">
            <section class="alert alert--error" *ngIf="patient && ((order.type === 'TRAVEL' && order.proposedVaccinations.length == 0) || (order.type === 'BOOKING'  && order.deliveryType === 'PHARMACY'))">
              Please contact the patient to advise them the item(s) are ready for collection: <i class="fa fa-phone"></i> {{patient.phone}}
            </section>
            <section class="alert alert--error" *ngIf="patient && ((order.type === 'TRAVEL' && order.proposedVaccinations.length > 0))">
              This prescription contains Vaccinations, please confirm stock availability and contact the Patient to arrange a time and date for administration. Patient contact number: <i class="fa fa-phone"></i> {{patient.phone}}
            </section>
            <section class="alert alert--error" *ngIf="patient && ((order.type === 'BOOKING'  && order.deliveryType === 'HOME'))">
              Please note this is a Delivery Order. Please contact the patient to advise them when the pharmacy will arrange delivery of the item(s) and to advise the patient the cost of the item(s): <i class="fa fa-phone"></i> {{patient.phone}}
            </section>
            <section class="alert alert--error"  *ngIf="patient && order.deliveryType === 'HOME' && order.paymentType === 'ONLINE' && ((order.type === 'FORM') || (order.type === 'TRAVEL' && order.proposedVaccinations.length === 0)) ">
              Please note this is a Delivery Order to the address of the patient. To check the address information please click on the “View” button. 
            </section>
          </div>
          <div class="row">
            <div class="col-md-6 form-group">
              <label>PIN Number</label>
              <app-masked-input [(value)]="pin"></app-masked-input>
            </div>
            <div class="col-md-6 form-group">
              <label>Name of Pharmacist</label>
              <input class="form-control" type="text" name="name" required [(ngModel)]="name" />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-xs-6 col-sm-3">
              <button type="reset" class="btn btn-sm btn-default btn-block mt-0" (click)="approveModal.hide()">Cancel</button>
            </div>
            <div *ngIf="!isVerification && isLoggedIn && order.status === 'WAITING_FOR_PHARMACY_APPROVAL'" class="col-xs-6 col-sm-3">
              <button type="button" class="btn btn-sm btn-primary btn-block mt-0" [disabled]="approveForm.form.invalid || pin === ''" (click)="approve()">Approve</button>
            </div>
            <div *ngIf="!isVerification && isLoggedIn && order.status === 'AWAITING_PHARMACY_DISPENSE'" class="col-xs-6 col-sm-3">
              <button type="button" class="btn btn-sm btn-primary btn-block mt-0" [disabled]="approveForm.form.invalid || pin === ''" (click)="dispense()">Dispense</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div bsModal #dispatchModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-body">
        <form #dispatchForm="ngForm" (ngSubmit)="dispatchModal.hide(); dispatch()" autocomplete="off" novalidate>
          <div class="checkbox" *ngIf="order.paymentType === 'CASH' && order.deliveryType === 'PHARMACY'">
            <label>
            <input type="checkbox" name="confirmPayment" required ngModel [equal]="true" />
                Please confirm the payment amount of <strong>{{order.price | currency : 'GBP' : 'symbol' :'1.2-2'}}</strong> has been taken from the patient. The pharmacy is required to take this amount prior to supplying the medication.
            </label>
          </div>
          <div class="modal-body" *ngIf="(order.type === 'FORM' || order.type === 'TRAVEL') && order.paymentType === 'ONLINE' ">
            The payment of <strong>{{order.price | currency : 'GBP' : 'symbol' :'1.2-2'}}</strong> has been paid to The Treat it by the patient. Please do not collect any payment as this will be reimbursed on your next monthly statement. If you have any questions please contact us on 024 7745 0733.
          </div>
          <div class="modal-body" *ngIf="order.type === 'BOOKING' && order.deliveryType === 'PHARMACY'">
            <span *ngIf="!deliveryOrder">Please ensure the payment for this <strong>electronic</strong> prescription is taken from the patient as per your private prescription tariff. The pharmacy is required to take this amount prior to supplying the medication.</span>
            <span *ngIf="deliveryOrder">The patient will be advised the Prescription has now been posted by Royal Mail and will be with them shortly.</span>
          </div>
          <div class="modal-body" *ngIf="order.type === 'BOOKING' && order.deliveryType === 'HOME' ">
            Please ensure the payment for this <strong>electronic</strong> prescription is taken from the patient as per your private prescription tariff. The pharmacy is required to take this amount at the time of delivering the items(s) to the patient.
          </div>
          <span>
            <div class="form-group">
              <label>Delivery Method</label>
              <!-- <input class="form-control" type="text" name="deliveryType" required [(ngModel)]="deliveryType" disabled/> -->

              <select class="form-control" [(ngModel)]="selectedDeliveryMethod" name="selectedDeliveryMethod" (change)="onSelect($event)" required>
                <option *ngFor="let deliveryMethod of deliveryMethods" [value]="deliveryMethod">{{ formatString(deliveryMethod) }}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Tracking Number</label>
              <input class="form-control" type="text" name="trackingNumber" [(ngModel)]="trackingNumber" required/>
            </div>
          </span>
          <div class="form-group">
            <label>PIN Number</label>
            <app-masked-input [(value)]="pin"></app-masked-input>
          </div>
          <div class="form-group">
            <label>Name of Pharmacist</label>
            <input class="form-control" type="text" name="name" required [(ngModel)]="name" />
          </div>
          <div class="checkbox">
            <label class="red-text">
                <input type="checkbox" ngModel name="verifiedID" required>
                <div *ngIf="order.patient.externallyIdentified">
                  <span *ngIf="!deliveryOrder"> I confirm that the identity of the patient will be verified when the medication is supplied.</span>
                  <span *ngIf="deliveryOrder && virtualPharmacy">I confirm I have checked the Delivery Type that was selected by the patient.</span>
                </div>
                <div *ngIf="!order.patient.externallyIdentified">I confirm that photographic proof of identity of the patient (e.g. Passport or Driver's License) will be checked before supplying any medication.
                </div>
                <p *ngIf="!deliveryOrder && order.type != 'FORM'" class="txt-primary">Please note that a printed copy of the prescription can be used for dispensing purposes. However, an electronic prescription must be received before supplying item(s) to the patient.</p>
            </label>
          </div>
          <button type="reset" class="btn btn-sm btn-default" (click)="dispatchModal.hide()">Cancel</button>
          <button [disabled]="dispatchForm.form.invalid || pin === ''" type="submit" class="btn btn-sm btn-primary">Dispatch</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div bsModal #completeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-body">
        <form #completeForm="ngForm" (ngSubmit)="completeModal.hide(); complete()" autocomplete="off" novalidate>
          <div class="checkbox" *ngIf="order.paymentType === 'CASH' && order.deliveryType === 'PHARMACY'">
            <label>
            <input type="checkbox" name="confirmPayment" required ngModel [equal]="true" />
                Please confirm the payment amount of <strong>{{order.price | currency : 'GBP' : 'symbol' :'1.2-2'}}</strong> has been taken from the patient. The pharmacy is required to take this amount prior to supplying the medication.
            </label>
          </div>
          <div class="modal-body" *ngIf="(order.type === 'FORM' || order.type === 'TRAVEL') && order.paymentType === 'ONLINE' ">
            The payment of <strong>{{order.price | currency : 'GBP' : 'symbol' :'1.2-2'}}</strong> has been paid to The Treat it by the patient. Please do not collect any payment as this will be reimbursed on your next monthly statement. If you have any questions please contact us on 024 7745 0733.
          </div>
          <div class="modal-body" *ngIf="order.type === 'BOOKING' && order.deliveryType === 'PHARMACY'">
            <span *ngIf="!deliveryOrder">Please ensure the payment for this <strong>electronic</strong> prescription is taken from the patient as per your private prescription tariff. The pharmacy is required to take this amount prior to supplying the medication.</span>
            <span *ngIf="deliveryOrder">The patient will be advised the Prescription has now been posted by Royal Mail and will be with them shortly.</span>
          </div>
          <div class="modal-body" *ngIf="order.type === 'BOOKING' && order.deliveryType === 'HOME' ">
            Please ensure the payment for this <strong>electronic</strong> prescription is taken from the patient as per your private prescription tariff. The pharmacy is required to take this amount at the time of delivering the items(s) to the patient.
          </div>
          <span>
            <div class="form-group">
              <label>Delivery Method</label>
              <!-- <input class="form-control" type="text" name="deliveryType" required [value]="formatString(order.deliveryMethod)" disabled/> -->
              <input class="form-control" type="text" name="deliveryType" [value]="formatString(order.deliveryMethod)" disabled/>
            </div>
          </span>
          <div class="form-group">
            <label for="eventDate">Delivery Date</label>
            <input class="form-control" type="date" name="eventDate" id="eventDate" required [(ngModel)]="eventDate" [min]="getOrderCreatedDate()" [max]="getTodayDate()" onkeydown="return false"/>
          </div>
          <div class="form-group">
            <label>PIN Number</label>
            <app-masked-input [(value)]="pin"></app-masked-input>
          </div>
          <div class="form-group">
            <label>Name of Pharmacist</label>
            <input class="form-control" type="text" name="name" required [(ngModel)]="name" />
          </div>
          <div class="checkbox">
            <label class="red-text">
                <input type="checkbox" ngModel name="verifiedID" required>
                <div *ngIf="order.patient.externallyIdentified">
                  <span *ngIf="!deliveryOrder"> I confirm that the identity of the patient will be verified when the medication is supplied.</span>
                  <span *ngIf="deliveryOrder && virtualPharmacy">I confirm I have checked the Delivery Type that was selected by the patient.</span>
                </div>
                <div *ngIf="!order.patient.externallyIdentified">I confirm that photographic proof of identity of the patient (e.g. Passport or Driver's License) will be checked before supplying any medication.
                </div>
                <p *ngIf="!deliveryOrder && order.type != 'FORM'" class="txt-primary">Please note that a printed copy of the prescription can be used for dispensing purposes. However, an electronic prescription must be received before supplying item(s) to the patient.</p>
            </label>
          </div>
          <button type="reset" class="btn btn-sm btn-default" (click)="completeModal.hide()">Cancel</button>
          <button [disabled]="completeForm.form.invalid || pin === ''" type="submit" class="btn btn-sm btn-primary">Complete</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div bsModal #cancelModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <form #cancelForm="ngForm" (ngSubmit)="cancelModal.hide(); cancelOrder()" autocomplete="off" novalidate>

          <div class="form-group">
            <label>PIN Number</label>
            <app-masked-input [(value)]="pin"></app-masked-input>
          </div>
          <div class="form-group">
            <label>Name of Pharmacist</label>
            <input class="form-control" type="text" name="name" required [(ngModel)]="name" />
          </div>
          <button type="reset" class="btn btn-sm btn-default" (click)="cancelModal.hide()">Cancel</button>
          <button [disabled]="cancelForm.form.invalid || pin === ''" type="submit" class="btn btn-sm btn-danger">Cancel Order</button>
        </form>
      </div>
    </div>
  </div>
</div>
